<template>
  <div
    class="tw-flex tw-items-center"
    :class="{ 'success--text': trendSign === 1, 'error--text': trendSign === -1 }"
    v-if="trend !== 0"
  >
    <v-icon color="success" v-if="trendSign === 1">{{ icons.mdiTrendingUp }}</v-icon>
    <v-icon color="error" v-else-if="trendSign === -1">{{ icons.mdiTrendingDown }}</v-icon>
    <div class="tw-font-medium tw-ml-1">
      <span v-if="trendSign === 1">+</span>
      <span>{{ numberFormat({ number: trend }) }}</span>
      <span v-if="percentage">%</span>
    </div>
  </div>
</template>

<script>
import { numberFormat } from '@/utils/helper.util'
import { mdiTrendingUp, mdiTrendingDown } from '@mdi/js'

export default {
  name: 'ProfileHubDashboardTrend',
  props: {
    trend: {
      type: Number,
      required: false,
      default: 0,
    },
    percentage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiTrendingUp,
      mdiTrendingDown,
    },
    numberFormat,
  }),
  computed: {
    trendSign() {
      return Math.sign(this.trend)
    },
  },
}
</script>
