<template>
  <UiCard :title="$t('profilehub.dashboard.segments.total')">
    <template v-slot:content>
      <div class="tw-flex tw-flex-col tw-items-center tw--mt-2" v-if="data">
        <div class="tw-text-5xl tw-font-bold tw-tracking-tight">{{ data.total }}</div>
        <div class="tw-text-lg tw-font-medium">{{ $t('profilehub.dashboard.segments.label.segments') }}</div>
        <div class="tw-flex tw-items-baseline tw-justify-center tw-w-full tw-my-4 sm:tw-my-6">
          <div class="tw-text-sm tw-font-medium tw-truncate">
            {{ $t('profilehub.dashboard.segments.label.custom') }}
          </div>
          <div class="tw-ml-1.5 tw-text-base tw-font-semibold">{{ data.custom }}</div>
        </div>
      </div>

      <ProfileHubDashboardNoData class="tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6" v-else />
    </template>
  </UiCard>
</template>

<script>
import UiCard from '@/components/UI/Card.vue'
import ProfileHubDashboardNoData from '@/components/ProfileHub/Dashboard/NoData.vue'

export default {
  name: 'ProfileHubDashboardTotalSegments',
  components: {
    UiCard,
    ProfileHubDashboardNoData,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
