<template>
  <UiCard :title="$t('profilehub.dashboard.overview.lastEntries')">
    <template v-slot:content>
      <v-list class="tw--mt-2" dense v-if="data.length">
        <template v-for="(item, idx) in data">
          <v-list-item :key="`item${idx}`" link class="sm:tw-px-6">
            <v-list-item-content>
              <v-list-item-title v-text="item.email" class="tw-text-sm"></v-list-item-title>
              <v-list-item-subtitle v-text="item.touchpoint"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text class="tw-flex tw-items-center">
                <v-icon small class="tw-mr-2">{{ icons.mdiClockOutline }}</v-icon>
                {{ timeFromNow(item.created_at, true) }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="idx < data.length - 1" :key="`divider${idx}`"></v-divider>
        </template>
      </v-list>
      <ProfileHubDashboardNoData class="tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6" v-else />
    </template>
  </UiCard>
</template>

<script>
import { timeFromNow } from '@/utils/date.util'
import { mdiClockOutline, mdiInformationOutline } from '@mdi/js'
import UiCard from '@/components/UI/Card.vue'
import ProfileHubDashboardNoData from '@/components/ProfileHub/Dashboard/NoData.vue'

export default {
  name: 'ProfileHubDashboardLastEntries',
  components: {
    UiCard,
    ProfileHubDashboardNoData,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    timeFromNow,
    icons: {
      mdiClockOutline,
      mdiInformationOutline,
    },
  }),
}
</script>
