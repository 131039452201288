<template>
  <UiCard :title="title">
    <template v-slot:content>
      <v-list class="tw--mt-2 tw-mb-4" dense flat v-if="data.length">
        <template v-for="(item, idx) in data">
          <v-list-item :key="idx" link class="sm:tw-px-6">
            <v-list-item-icon class="tw-mr-4">
              <v-icon :color="type === 'top' ? 'success' : 'error'">{{ icons.numbers[idx] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" class="tw-text-sm"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <ProfileHubDashboardNoData class="tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6" v-else />
    </template>
  </UiCard>
</template>

<script>
import {
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiNumeric4CircleOutline,
  mdiNumeric5CircleOutline,
} from '@mdi/js'
import UiCard from '@/components/UI/Card.vue'
import ProfileHubDashboardNoData from '@/components/ProfileHub/Dashboard/NoData.vue'

export default {
  name: 'ProfileHubDashboardListSegments',
  components: {
    UiCard,
    ProfileHubDashboardNoData,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    icons: {
      numbers: [
        mdiNumeric1CircleOutline,
        mdiNumeric2CircleOutline,
        mdiNumeric3CircleOutline,
        mdiNumeric4CircleOutline,
        mdiNumeric5CircleOutline,
      ],
    },
  }),
}
</script>
