<template>
  <div class="ui-profile-hub-metric">
    <div class="ui-profile-hub-metric__value">
      {{ numberFormat({ number: value }) }}<span v-if="percentage">%</span>
    </div>
    <slot name="subvalue"></slot>
  </div>
</template>

<script>
import { numberFormat } from '@/utils/helper.util'

export default {
  name: 'ProfileHubDashboardMetric',
  props: {
    value: {
      type: Number,
      required: true,
    },
    percentage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    numberFormat,
  }),
}
</script>

<style lang="scss" scoped>
.ui-profile-hub-metric {
  @apply tw-flex tw-mx-4 sm:tw-mx-6 tw--my-1 tw-items-center;

  &__value {
    @apply tw-text-5xl tw-font-semibold tw-tracking-tighter tw-leading-none;
  }
}
</style>
