<template>
  <UiCard :title="$t('profilehub.dashboard.overview.profilesNumber')">
    <template v-slot:content>
      <ProfileHubDashboardMetric :value="data.total" :class="{ 'tw-pb-4 sm:tw-pb-6': !isChartDisplayed }" />
      <div class="tw-flex tw-flex-col tw-flex-auto tw-h-20" v-if="isChartDisplayed">
        <UiApexChart :options="chartOptions" :series="chartSeries" />
      </div>
    </template>
  </UiCard>
</template>

<script>
import UiCard from '@/components/UI/Card.vue'
import UiApexChart from '@/components/UI/ApexChart.vue'
import ProfileHubDashboardMetric from '@/components/ProfileHub/Dashboard/Metric.vue'

export default {
  name: 'ProfileHubDashboardOverview',
  components: {
    UiCard,
    UiApexChart,
    ProfileHubDashboardMetric,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isChartDisplayed() {
      return this.data.monthes.length > 1
    },
    chartOptions() {
      return {
        chart: {
          type: 'area',
        },
        colors: ['#38bdf8'],
        xaxis: {
          categories: this.chart.categories,
        },
        grid: {
          padding: {
            top: 12,
          },
        },
      }
    },
    chartSeries() {
      return [
        {
          name: 'Profiles',
          data: this.chart.series,
        },
      ]
    },
  },
}
</script>
