<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="profile-hub-dashboard" v-else>
      <UiBanner :title="$t('profilehub.dashboard.title')" :subtitle="$t('profilehub.dashboard.subtitle')" />
      <UiContainer>
        <div class="profile-hub-dashboard__section">
          <UiTitle :title="$t('profilehub.dashboard.overview.title')" />
          <UiSubtitle :subtitle="$t('profilehub.dashboard.overview.subtitle')" />
          <div class="profile-hub-dashboard__section__grid">
            <ProfileHubDashboardOverview
              class="sm:tw-col-span-3"
              :data="dashboard.totalProfiles"
              :chart="totalProfilesChart"
            />
            <ProfileHubDashboardLastEntries class="sm:tw-col-span-3" :data="dashboard.lastEntries" />
            <ProfileHubDashboardOptInCustom
              class="sm:tw-col-span-6 lg:tw-col-span-2"
              :data="dashboard.optInCustom"
              :chart="optInCustomChart"
            />
            <ProfileHubDashboardOptInProportion
              class="sm:tw-col-span-3 lg:tw-col-span-2"
              :data="dashboard.optInProportion"
              :chart="optInProportionChart"
            />
            <ProfileHubDashboardAverageKnowledge
              class="sm:tw-col-span-3 lg:tw-col-span-2"
              :data="dashboard.averageKnowledge"
              :chart="averageKnowledgeChart"
            />
          </div>
        </div>
        <div class="profile-hub-dashboard__section">
          <UiTitle :title="$t('profilehub.dashboard.segments.title')" />
          <UiSubtitle :subtitle="$t('profilehub.dashboard.segments.subtitle')" />
          <div class="profile-hub-dashboard__section__grid">
            <ProfileHubDashboardTotalSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :data="dashboard.totalSegments"
            />
            <ProfileHubDashboardListSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :title="$t('profilehub.dashboard.segments.mostUsed')"
              :data="dashboard.mostUsedSegments"
              type="top"
            />
            <ProfileHubDashboardListSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :title="$t('profilehub.dashboard.segments.leastUsed')"
              :data="dashboard.leastUsedSegments"
              type="flop"
            />
            <ProfileHubDashboardDefaultSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :title="$t('profilehub.dashboard.segments.genders')"
              :data="dashboard.genders"
              :chart="gendersChart"
              :colors="['#9FF2FF', '#FF7999', '#8A74FF', '#F0F5F9']"
              obj="gender"
            />
            <ProfileHubDashboardDefaultSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :title="$t('profilehub.dashboard.segments.ages')"
              :data="dashboard.ages"
              :chart="agesChart"
              :colors="['#63b3ed', '#3182bd', '#0f588c', '#043d66', '#01243d']"
              obj="age"
            />
            <ProfileHubDashboardDefaultSegments
              class="sm:tw-col-span-3 md:tw-col-span-2"
              :title="$t('profilehub.dashboard.segments.countries')"
              :data="dashboard.countries"
              :chart="countriesChart"
              :colors="['#edcf63', '#bd9131', '#8c5e0f', '#663d04', '#3d2501']"
              obj="country"
            />
          </div>
        </div>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import ProfileHubDashboardOverview from '@/components/ProfileHub/Dashboard/Overview.vue'
import ProfileHubDashboardLastEntries from '@/components/ProfileHub/Dashboard/LastEntries.vue'
import ProfileHubDashboardOptInCustom from '@/components/ProfileHub/Dashboard/OptInCustom.vue'
import ProfileHubDashboardOptInProportion from '@/components/ProfileHub/Dashboard/OptInProportion.vue'
import ProfileHubDashboardAverageKnowledge from '@/components/ProfileHub/Dashboard/AverageKnowledge.vue'
import ProfileHubDashboardTotalSegments from '@/components/ProfileHub/Dashboard/TotalSegments.vue'
import ProfileHubDashboardListSegments from '@/components/ProfileHub/Dashboard/ListSegments.vue'
import ProfileHubDashboardDefaultSegments from '@/components/ProfileHub/Dashboard/DefaultSegments.vue'

export default {
  name: 'ProfileHubDashboard',
  components: {
    UiBanner,
    UiContainer,
    UiTitle,
    UiSubtitle,
    ProfileHubDashboardOverview,
    ProfileHubDashboardLastEntries,
    ProfileHubDashboardOptInCustom,
    ProfileHubDashboardOptInProportion,
    ProfileHubDashboardAverageKnowledge,
    ProfileHubDashboardTotalSegments,
    ProfileHubDashboardListSegments,
    ProfileHubDashboardDefaultSegments,
  },
  async created() {
    this.setUpdating(true)
    await this.getDashboard()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      dashboard: state => state.profilehub.dashboard,
    }),
    ...mapGetters({
      totalProfilesChart: 'profilehub/totalProfilesChart',
      optInCustomChart: 'profilehub/optInCustomChart',
      optInProportionChart: 'profilehub/optInProportionChart',
      averageKnowledgeChart: 'profilehub/averageKnowledgeChart',
      gendersChart: 'profilehub/gendersChart',
      agesChart: 'profilehub/agesChart',
      countriesChart: 'profilehub/countriesChart',
    }),
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getDashboard: 'profilehub/getDashboard',
    }),
  },
}
</script>

<style lang="scss" scoped>
.profile-hub-dashboard {
  &__section {
    @apply tw-mt-12;

    &:first-child {
      @apply tw-mt-0;
    }

    &__grid {
      @apply tw-mt-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-6 tw-gap-6;
    }
  }
}
</style>
