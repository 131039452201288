<template>
  <UiCard :title="title">
    <template v-slot:content>
      <template v-if="data.length">
        <div class="tw-flex tw-flex-col tw-h-40">
          <UiApexChart
            :options="chartOptions"
            :series="chartSeries"
            class="tw-flex tw-flex-auto tw-h-full tw-items-center tw-justify-center"
          />
        </div>

        <div class="tw-p-4 sm:tw-p-6">
          <div class="tw--my-3 tw-divide-y tw-divide-x-0 tw-divide-solid tw-text-sm">
            <div
              class="tw-grid tw-grid-cols-4 tw-py-2 dark:tw-border-opacity-10"
              v-for="(item, idx) in data"
              :key="`segment${idx}`"
            >
              <div class="tw-flex tw-items-center tw-col-span-2">
                <div class="tw-flex-0 tw-w-2 tw-h-2 tw-rounded-full" :style="{ backgroundColor: colors[idx] }"></div>
                <div class="tw-ml-3 tw-truncate">
                  {{
                    obj === 'country'
                      ? countryName(item[obj])
                      : $t(`profilehub.dashboard.segments.label.${item[obj] || 'other'}`)
                  }}
                </div>
              </div>
              <div class="tw-font-medium tw-text-right">{{ item.count }}</div>
              <div class="tw-text-right">{{ numberFormat({ number: item.percent, digit: 2 }) }}%</div>
            </div>
          </div>
        </div>
      </template>
      <ProfileHubDashboardNoData class="tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6" v-else />
    </template>
  </UiCard>
</template>

<script>
import { numberFormat } from '@/utils/helper.util'
import { countries } from '@/config/countries.config'
import UiCard from '@/components/UI/Card.vue'
import UiApexChart from '@/components/UI/ApexChart.vue'
import ProfileHubDashboardNoData from '@/components/ProfileHub/Dashboard/NoData.vue'

export default {
  name: 'ProfileHubDashboardDefaultSegments',
  components: {
    UiCard,
    UiApexChart,
    ProfileHubDashboardNoData,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
    obj: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    numberFormat,
  }),
  computed: {
    computedLabels() {
      return this.chart.categories.map(c => {
        if (this.obj === 'country') {
          return this.countryName(c)
        }
        return this.$t(`profilehub.dashboard.segments.label.${c || 'other'}`)
      })
    },
    chartOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.computedLabels,
        colors: this.colors,
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
      }
    },
    chartSeries() {
      return this.chart.series
    },
  },
  methods: {
    countryName(ctry) {
      const country = countries[this.$i18n.locale].find(c => c.code === ctry)
      return country ? country.name : this.$t(`profilehub.dashboard.segments.label.${ctry || 'other'}`)
    },
  },
}
</script>
