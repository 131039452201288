<template>
  <UiCard :title="$t('profilehub.dashboard.overview.optInProportion')">
    <template v-slot:content>
      <ProfileHubDashboardMetric :value="data.total" :class="{ 'tw-pb-4 sm:tw-pb-6': !isChartDisplayed }" percentage>
        <template v-slot:subvalue>
          <ProfileHubDashboardTrend class="tw-ml-4" :trend="data.evolution" />
        </template>
      </ProfileHubDashboardMetric>
      <div class="tw-flex tw-flex-col tw-flex-auto tw-h-20" v-if="isChartDisplayed">
        <UiApexChart :options="chartOptions" :series="chartSeries" />
      </div>
    </template>
  </UiCard>
</template>

<script>
import UiCard from '@/components/UI/Card.vue'
import UiApexChart from '@/components/UI/ApexChart.vue'
import ProfileHubDashboardMetric from '@/components/ProfileHub/Dashboard/Metric.vue'
import ProfileHubDashboardTrend from '@/components/ProfileHub/Dashboard/Trend.vue'

export default {
  name: 'ProfileHubDashboardOptInProportion',
  components: {
    UiCard,
    UiApexChart,
    ProfileHubDashboardMetric,
    ProfileHubDashboardTrend,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isChartDisplayed() {
      return this.data.optin.length > 1
    },
    chartOptions() {
      return {
        chart: {
          type: 'area',
        },
        colors: ['#9543d3'],
        xaxis: {
          categories: this.chart.categories,
        },
        grid: {
          padding: {
            top: 12,
          },
        },
        tooltip: {
          y: {
            formatter: value => `${value}%`,
          },
        },
      }
    },
    chartSeries() {
      return [
        {
          name: 'Percentage',
          data: this.chart.series,
        },
      ]
    },
  },
}
</script>
