<template>
  <v-card class="ui-card">
    <div class="ui-card__header">
      <div class="ui-card__header__section ui-card__header__section--left">
        <div class="ui-card__header__section__title">
          <slot name="title">{{ title }}</slot>
        </div>
        <div class="ui-card__header__section__subtitle">
          <slot name="subtitle">{{ subtitle }}</slot>
        </div>
      </div>
      <div class="ui-card__header__section ui-card__header__section--right">
        <slot name="actions"></slot>
      </div>
    </div>
    <slot name="content"></slot>
  </v-card>
</template>

<script>
export default {
  name: 'UiCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-card {
  @apply tw-flex tw-flex-col tw-flex-auto tw-shadow tw-rounded-2xl tw-overflow-hidden;

  &__header {
    @apply tw-flex tw-items-start tw-justify-between tw-p-4 sm:tw-p-6;

    &__section {
      &--left {
        @apply tw-flex tw-flex-col tw-overflow-hidden;
      }

      &--right {
        @apply tw-ml-4;
      }

      &__title {
        @apply tw-font-medium tw-leading-6 tw-truncate tw-tracking-tight;
      }

      &__subtitle {
        @apply tw-text-xs;
      }
    }
  }
}
</style>
