<template>
  <div class="tw-flex tw-items-center">
    <v-icon class="tw-mr-2">{{ icons.mdiAlertCircleOutline }}</v-icon>
    <div>{{ $t('label.noData') }}</div>
  </div>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'ProfileHubDashboardNoData',
  data: () => ({
    icons: {
      mdiAlertCircleOutline,
    },
  }),
}
</script>
